import { Button } from "@bolt/components";
import { memo } from "react";

import "./LogOutButtonStyles.css";
import { useSetUserInfoContext } from "../contexts/UserInfoContext";

const LogOutButton = () => {
  const setUserInfo = useSetUserInfoContext();
  const handleLogout = async () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const index = cookies[i].indexOf("=");
      const name = cookies[i].slice(0, index);
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    setUserInfo(null);
  };

  return (
    <Button
      variant="outline"
      size="lg"
      data-testid="logout-button"
      className="logout-button"
      onClick={handleLogout}
      iconLeft="right-from-bracket"
      title="Log out"
    >
      <span className="hide-on-mobile">Log out</span>
    </Button>
  );
};

export default memo(LogOutButton);
