import React from "react";
import "./GuidancePageContentStyles.css";

const UserGuideInfoContent = () => (
  <div className="text-container">
    <h1>User Guide</h1>
    <h2>Intro</h2>
    <ul>
      <li>
        <strong>
          By using this tool, you authorize GPC to look at everything you do in
          ChatGPC.
        </strong>
      </li>
      <li>
        ChatGPC is designed to assist GPC’s employees and contractors in
        creating high-quality content faster and more efficiently.
      </li>
      <li>
        This user guide provides instructions and best practices for optimal use
        of the tool.
      </li>
      <li>
        Please, familiarize yourself with this guide. If you have any additional
        questions or concerns, you can reach us at{" "}
        <a
          href="mailto:GEN_AI_Special_Projects@genpt.com"
          target="_blank"
          rel="noreferrer"
        >
          GEN_AI_Special_Projects@genpt.com
        </a>
        .
      </li>
    </ul>
    <h2>Getting started</h2>
    <ul>
      <li>Access ChatGPC via one of these addresses:</li>
      <ul>
        <li>
          https://chat-gpc.stress.genpt.com/ - internal testing instance (closed
          beta)
        </li>
        <li>https://chat-gpc.genpt.com/ - production instance.</li>
        <li>
          Click on `Log in` button on the left side and log in with your
          username (e.g. `253784`) and password.
        </li>
        <li>
          If prompted, set up Multi Factor Authentication, either using your
          company e-mail address or a mobile app (follow the instructions on the
          screen). You will need to input a one-time code each time you log in.
        </li>
        <li>
          <strong>Read the “Generative AI Guidance" and accept it.</strong>
        </li>
        <li>You can start using the tool!</li>
      </ul>
    </ul>
    <h2>Using the tool</h2>
    <ul>
      <li>
        Input your question or prompt the `Ask me a question...` text field.
      </li>
      <li>Click on the `Send` button.</li>
      <li>
        You can reset the session and start over by clicking the `Clear history`
        button.
      </li>
    </ul>
    <h2>DO’S and DON’TS </h2>
    <h3>DO’S </h3>
    <ul>
      <li>Only use input non-personal data.</li>
      <li>
        Understand chat limitations. LLMs are unable to process feelings, read
        emotions or intentions behind a message.{" "}
      </li>
      <li>Keep your questions polite, casual, and approachable.</li>
      <li>
        {" "}
        Help us improve the solution. Send us feedback via Thumbs Up/Down
        mechanism built in the chat.{" "}
      </li>
      <li>
        Have an explicit permission from your direct manager to use this tool.
      </li>
      <li>
        <strong>
          Always verify and fact-check the outputs. Sometimes LLMs hallucinate
          (generate incorrect or fabricated information).
        </strong>
      </li>
      <li>
        <strong>Use common sense - HR guidelines apply at all times</strong>
      </li>
    </ul>
    <h3>DON’TS</h3>
    <ul>
      <li>Don’t use tool for personal tasks.</li>
      <li>
        Don’t stereotype. Responses should avoid making presumptions based on
        race, nationality, gender, age, religion, sexual orientation, political
        ideology, location, or similar categories.{" "}
      </li>
      <li>
        Don’t use the LLMs to obtain any type of unethical or harmful content.
        Full list of DO’S and DONT’S can be found in the GenAI Guidance
        document.
      </li>
    </ul>
    <h2>Best prompting practices</h2>
    <ol>
      <li>Give clear and specific instructions</li>
      <ol>
        <li>Good:</li>
        <ol>
          <li>`Summarize the following text:...`</li>
        </ol>
        <li>Better:</li>
        <ol>
          <li>`Summarize the following text in four sentences or less:...`</li>
          <li>
            {" "}
            `Summarize the following text. Include key concepts in bullet
            points. Finally, list the future text ideas.`{" "}
          </li>
        </ol>
      </ol>
      <li>You can assign roles to improve the output</li>
      <ol>
        <li>Good:</li>
        <ol>
          <li>`Write a short description of the new Dune movie.`</li>
        </ol>
      </ol>
      <ol>
        <li>Better:</li>
        <ol>
          <li>
            `You are a witty movie critic. Write a short, humorous review of the
            latest Dune movie.`
          </li>
        </ol>
      </ol>
      <li>Provide examples</li>
      <ol>
        <li>Zero-shot prompting:</li>
        <ol>
          <li>`Provide a two-sentence summary of this text: ...`</li>
        </ol>
        <li>One-shot prompting:</li>
        <ol>
          <li>
            ` Provide a two-sentence summary of the text.
            <br />
            Example:
            <br />
            - Text: [example text]
            <br />
            Summary: [example summary] <br />
            Text: [your text]`
          </li>
        </ol>
        <li>Few-shot prompting:</li>
        <ol>
          <li>
            ` Provide a two-sentence summary of the text. <br />
            Examples: <br />- Text: [example text 1] Summary: [example summary
            1] <br />- Text: [example text 2] Summary: [example summary 2]{" "}
            <br />
            Text: [your text]`
          </li>
        </ol>
      </ol>
      <li>Try iterating/experimenting with the order of the prompt content:</li>
      <ol>
        <li>
          First prompt:
          <br />
          [examples]
          <br />
          [context]
          <br />
          [input]
        </li>
        <li>
          Second prompt:
          <br />
          [input]
          <br />
          [examples]
          <br />
          [context]
        </li>
        <li>
          Third prompt:
          <br />
          [examples]
          <br />
          [input]
          <br />
          [context]
        </li>
      </ol>
      <li>Combine best practices</li>
      <ol>
        <li>
          Try combining different techniques mentioned above to get results that
          work the best for you
        </li>
      </ol>
    </ol>
  </div>
);

export default UserGuideInfoContent;
