import { PingOneResponse, UserInfo } from "../types";

export class AuthService {
  static async getAccessToken(code: string): Promise<UserInfo> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/token?code=${code}&redirect_uri=${process.env.REACT_APP_BASE_URL}`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
      idToken: response.id_token,
    };
  }

  static async refreshToken(refresh_token: string): Promise<UserInfo> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refresh_token}`,
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      process.env.REACT_APP_BACKEND_URL + `/api/v1/refresh`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
      idToken: response.id_token,
    };
  }
}
