import React from "react";
import ReactMarkdown from "react-markdown";

import "./GuidancePageContentStyles.css";
import useGenerativeAIGuidance from "../../../hooks/useGenerativeAIGuidance";

const GuidanceInfoContent = () => {
  const text = useGenerativeAIGuidance();

  return (
    <div className="text-container">
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

export default GuidanceInfoContent;
