import clsx from "clsx";
import { memo, useEffect, useRef, useState } from "react";
import { MdContentCopy } from "react-icons/md";

import "./CopyToClipboardStyles.css";

type CopyToClipboardProps = {
  textToCopy: string;
  className?: string;
};

const CopyToClipboard = ({ textToCopy, className }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
      timeoutRef.current = null;
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <button
      className={clsx("btn-link", isCopied && "btn-active", className)}
      onClick={handleCopyToClipboard}
      title="Click to copy!"
    >
      {isCopied && <span className="copied-label">Copied! </span>}
      <MdContentCopy size="20" />
    </button>
  );
};

export default memo(CopyToClipboard);
