import "./LoadingPageStyles.css";
import { Loading } from "@bolt/components";

const LoadingPage = () => (
  <div className="loading-page" role="progressbar">
    <Loading size="lg" />
  </div>
);

export default LoadingPage;
