import { FeedbackThumbsType } from "../types";

type SendFeedbackType = {
  questionId: string;
  thumbs: FeedbackThumbsType;
  userFeedback: string | null;
  accessToken: string;
  questionTimestamp: string;
};

export class FeedbackService {
  static async sendFeedback({
    questionId,
    thumbs,
    userFeedback,
    accessToken,
    questionTimestamp,
  }: SendFeedbackType): Promise<any> {
    const FEEDBACK_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/feedback";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": "chat-gpc-frontend",
      },
      body: JSON.stringify({
        question_id: questionId,
        question_timestamp: questionTimestamp,
        user_rating: thumbs === "up" ? 1 : -1,
        user_comment: userFeedback,
      }),
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(FEEDBACK_ENDPOINT, requestOptions);
      if (response.status > 299) {
        throw new Error();
      }

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
