import { useEffect, useState } from "react";

const useGenerativeAIGuidance = () => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    fetch("/generative-ai-guidance-v1.md")
      .then((r) => r.text())
      .then(setText);
  }, []);

  return text;
};

export default useGenerativeAIGuidance;
