import { ChatMessage } from "../types";
import ReactMarkdown from "react-markdown";
import { MdOutlineFaceUnlock } from "react-icons/md";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  nightOwl,
  prism,
} from "react-syntax-highlighter/dist/cjs/styles/prism";
import { ChatTheme } from "../hooks/useTheme";
import CopyToClipboard from "./CopyToClipboard";
import "./ChatMessageComponentStyles.css";
import ChatErrorMessage from "./ChatErrorMessage";
import ChatGPCLogomark from "./ChatGPCLogomark";
import { Button } from "@bolt/components";
import ChatActions from "./ChatActions";

type ChatMessageComponentProps = {
  message: ChatMessage;
  theme: ChatTheme;
  isLatest?: boolean;
  handleRegenerateResponse: () => void;
};

const ChatMessageComponent = ({
  message,
  theme,
  isLatest = false,
  handleRegenerateResponse,
}: ChatMessageComponentProps) => {
  if (message.error) {
    return (
      <>
        <ChatErrorMessage errorMessage={message.content} />
        {isLatest && (
          <div className="chat-action-button-wrapper">
            <Button
              onClick={handleRegenerateResponse}
              className="regenerate-response-chat-button"
              size="lg"
              variant="solid"
              intent="primary"
            >
              Regenerate your question
            </Button>
          </div>
        )}
      </>
    );
  }

  if (message.role === "assistant") {
    return (
      <>
        <div className="message bot-message">
          <ChatGPCLogomark className="chat-img" />
          <ReactMarkdown
            className="chat-markdown"
            // Note: taken from documentation:
            // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
            components={{
              code(props) {
                const { children, className, node, ...rest } = props;
                const match = /language-(\w+)/.exec(className || "");
                return match ? (
                  <div className="code-container">
                    <SyntaxHighlighter
                      {...rest}
                      showLineNumbers
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                      style={theme === "dark" ? nightOwl : prism}
                    />
                    <CopyToClipboard
                      className="copy-code-button"
                      textToCopy={String(children)}
                    />
                  </div>
                ) : (
                  <code {...rest} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {message.content}
          </ReactMarkdown>
        </div>
        {isLatest && message.questionId && message.questionTimestamp && (
          <ChatActions
            textToCopy={message.content}
            questionId={message.questionId}
            questionTimestamp={message.questionTimestamp}
          />
        )}
      </>
    );
  }

  return (
    <div className="message">
      <div>
        <MdOutlineFaceUnlock className="chat-img" size="100%" />
      </div>
      <p className="chat-markdown">{message.content}</p>
    </div>
  );
};

export default ChatMessageComponent;
