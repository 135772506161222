import { PropsWithChildren, createContext, useContext, useState } from "react";
import { UserInfo } from "../types";

export const UserInfoContext = createContext<UserInfo | null>(null);

export const SetUserInfoContext = createContext<
  React.Dispatch<React.SetStateAction<UserInfo | null>>
>(() => {});

export const useUserInfoContext = () => useContext(UserInfoContext);

export const useSetUserInfoContext = () => useContext(SetUserInfoContext);

const UserInfoContextWrapper = ({ children }: PropsWithChildren) => {
  const [userInfo, setUserInfo] = useState<null | UserInfo>(null);

  return (
    <UserInfoContext.Provider value={userInfo}>
      <SetUserInfoContext.Provider value={setUserInfo}>
        {children}
      </SetUserInfoContext.Provider>
    </UserInfoContext.Provider>
  );
};

export default UserInfoContextWrapper;
