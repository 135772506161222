import "./FooterLinksStyles.css";

export const FooterLinks = () => (
  <footer>
    <p>
      <a href="/gen-ai-guidance" rel="noreferrer">
        Guidance
      </a>
      <a href="/user-guide" rel="noreferrer">
        User Guide
      </a>
      <a
        href="mailto:gen_ai_special_projects@genpt.com"
        rel="noreferrer"
        target="_blank"
      >
        Contact Us
      </a>
    </p>
  </footer>
);

export default FooterLinks;
