import { ChatMessageAssistant, ChatRequest } from "../types";

export class ChatGpcService {
  static abortController: AbortController | undefined;

  static async abortQuestion() {
    this.abortController?.abort();
  }

  static async askQuestion(
    chatRequest: ChatRequest,
    accessToken: string,
    logOutCallback: () => void,
  ): Promise<ChatMessageAssistant> {
    this.abortQuestion();
    this.abortController = new AbortController();

    const CHAT_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/chat";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": "chat-gpc-frontend",
      },
      body: JSON.stringify(chatRequest),
      signal: this.abortController.signal,
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(CHAT_ENDPOINT, requestOptions);

      if (response.status > 299) {
        // If Unauthorized - than log out
        if (response.status === 401) {
          logOutCallback();
        }

        throw new Error();
      }

      const responseJson = await response.json();

      return {
        role: "assistant",
        content: responseJson.answer,
        questionId: responseJson.question_id,
        questionTimestamp: responseJson.question_timestamp,
        error: false,
      };
    } catch (error: any) {
      if (error.name === "AbortError") {
        return {
          content:
            "The response generation has been stopped. You can enter a new question or resubmit a previous one to continue.",
          role: "assistant",
          error: true,
        };
      }

      return {
        content:
          "We're sorry, there was an unexpected error. Please try resubmitting your question. If you keep getting this message, contact us.",
        role: "assistant",
        error: true,
      };
    }
  }
}
