import { Skeleton } from "@bolt/components";
import { memo } from "react";

import "./SkeletonTextBlockStyles.css";

const messagesArray = [
  1, 5, 7, 3, 5, 7, 1, 3, 10, 2, 7, 3, 7, 2, 8, 5, 2, 1, 5, 7, 3, 5, 7, 1, 3,
  10, 2, 7, 3, 7, 2, 8, 5, 2, 1, 5, 7, 3, 5, 7, 1, 3, 10, 2, 7, 3, 7, 2, 8, 5,
  2,
];

const SkeletonTextBlock = memo(() => (
  <div className="skeleton-text-block-container">
    {messagesArray.map((value, index) => (
      <Skeleton
        key={index}
        type="header"
        style={{
          width: `${value}%`,
          marginRight: "0.15rem",
          marginBottom: "0.2rem",
          height: "1.5rem",
        }}
      />
    ))}
  </div>
));

export default SkeletonTextBlock;
