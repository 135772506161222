import React, { Dispatch, SetStateAction } from "react";
import "./termsAndConditionsStyles.css";

import { display } from "../../../utils/displayValues";
import { TermsAndConditionsService } from "../../../services/TermsAndConditionsService";
import ChatGPCLogomark from "../../ChatGPCLogomark";
import GuidanceInfoContent from "../Info/GuidanceInfoContent";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../../../contexts/UserInfoContext";

type TermsAndConditionsProps = {
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
};

const TermsAndConditions = ({ setTermsAccepted }: TermsAndConditionsProps) => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;

  const handleAccept = async () => {
    setTermsAccepted(true);
    await TermsAndConditionsService.acceptTerms(userInfo.accessToken);
  };

  const handleDecline = async () => {
    setUserInfo(null);
  };

  // TODO: if (isLoadingTerms) return <div>Loading</div>
  return (
    <div>
      <div className="terms-header-bar">
        <ChatGPCLogomark className="gpc-logo-terms" />
      </div>
      <div className="terms-content">
        <div className="header-text">GENERATIVE AI GUIDANCE</div>
        <div className="terms-body">
          <GuidanceInfoContent />
        </div>
        <div className="red-text">
          By clicking “Accept”, I attest that I have read the GPC Employee
          Generative AI Guidance document and will abide by the policies and
          procedures set in place by GPC. I understand that failure to follow
          these policies and procedures may result in disciplinary actions up to
          and including termination.
        </div>
        <div className="button-group">
          <button onClick={handleDecline} className="decline-button">
            {display["en"].decline}
          </button>
          <button
            data-testid="accept-terms"
            className="accept-button"
            onClick={handleAccept}
          >
            {display["en"].accept}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
