import { PropsWithChildren } from "react";
import ChatGPCLogomark from "./ChatGPCLogomark";

const MessageBot = ({ children }: PropsWithChildren) => (
  <div className="message bot-message">
    <ChatGPCLogomark className="chat-img" />
    {children}
  </div>
);

export default MessageBot;
