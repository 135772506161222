import { PropsWithChildren } from "react";
import FooterLinks from "../../FooterLinks";
import ChatGPCLogo from "../../ChatGPCLogo";

import "./InfoTemplateStyles.css";

export const InfoTemplate = ({ children }: PropsWithChildren) => {
  return (
    <div className="page-informative-container" data-theme="light">
      <header>
        <ChatGPCLogo />
      </header>
      <main>{children}</main>
      <FooterLinks />
    </div>
  );
};
