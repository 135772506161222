export type displayLanguage = {
  en: displayConstants;
};

type displayConstants = {
  appHeader: string;
  appSubHeader: string;
  login: string;
  sidebarHeader: string;
  generalChatBotPrompt: string;
  accept: string;
  decline: string;
  disclosure: string;
};

export const display: displayLanguage = {
  en: {
    appHeader: "ChatGPC",
    appSubHeader: "Your Personal AI Assistant",
    login: "Log in",
    sidebarHeader: "ChatGPC",
    generalChatBotPrompt: `Hello, I'm a chatbot assistant. Ask me a question...`,
    accept: "Accept",
    decline: "Decline",
    disclosure:
      "Disclosure Notice! Do not input personal information into this tool. All usage of ChatGPC will be monitored and logged. Employees are expected to follow GPC policies and procedures as related to usage of ChatGPC. Always verify the accuracy and appropriateness of any outputs from ChatGPC.",
  },
};

// export default display;
