import { useCallback, useEffect, useState } from "react";

export type ChatTheme = "dark" | "light";

const useTheme = () => {
  const [theme, setTheme] = useState<ChatTheme>("light");

  const toggleTheme = useCallback(() => {
    setTheme((theme) => (theme === "light" ? "dark" : "light"));
  }, [setTheme]);

  // Note: This use callback is for changing the theme of @bolt design system
  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, [theme]);

  // revert light theme on logout
  useEffect(() => {
    return () => {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    };
  }, []);

  return { theme, toggleTheme };
};

export default useTheme;
